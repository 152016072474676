<template>
    <layout :hidecenter="true">
        <v-container class="loginpage mb-16">
            <breadcrumbs :items="breadcrumbs" :large="true" class="py-7" textcolor="textdark"></breadcrumbs>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-card flat>
                    <v-row>
                        <v-col cols="12" lg="7">
                            <v-card-text class="px-md-10 py-8">
                                <h1 class="text-28-60">Sign up to continue</h1>
                                <p class="pt-4 mb-8">Already have an account? <router-link :to="{ name: 'frontend.auth.login', params: {} }">Sign in</router-link></p>
                                <v-btn x-large class="bg-transparent py-8 text-14 text-transform-none w-100 googlelogin" depressed :ripple="false">
                                    <div class="d-flex justify-space-between w-100">
                                        <img src="@/assets/icons/auth/google.svg" alt="">
                                        <span>Sign up with Google</span>
                                        <span></span>
                                    </div>
                                </v-btn>
                                <div class="d-flex my-8 align-center">
                                    <div class="dotborder w-100"></div>
                                    <div class="flex-grow-1 flex-shrink-0 px-5">Or sign up with email</div>
                                    <div class="dotborder w-100"></div>
                                </div>
                                <v-snackbar
                                    v-model="snackbar"
                                    :timeout="1500"
                                    centered
                                    color="red"
                                >
                                    Email already in use
                                </v-snackbar>
                                <div class="">
                                    <label for="">Email</label>
                                    <v-text-field class="mt-2" v-model="form.email" v-on:keyup.enter="signUp" :rules="emailRules" placeholder="your@email.com" outlined background-color="silver"></v-text-field>
                                </div>
                                <div class="">
                                    <label for="">Password</label>
                                    <v-text-field
                                        class="mt-2"
                                        v-model="form.password"
                                        :rules="[v => (v && v.length >= 8) || 'Password must be more than 8 characters']"
                                        placeholder="Password"
                                        outlined background-color="silver"
                                        :append-icon="pass_show ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="pass_show ? 'text' : 'password'"
                                        @click:append="pass_show = !pass_show"
                                        v-on:keyup.enter="signUp"
                                    ></v-text-field>
                                </div>
                                <div class="">
                                    <v-checkbox
                                        class="checkbox mt-0 pt-0"
                                        :rules="[v => !!v || 'You must agree to continue!']"
                                    >
                                        <template v-slot:label>
                                            <span>I accept Freight Crew <router-link :to="{ name: 'frontend.terms', params: {} }">Terms & Conditions</router-link></span>
                                        </template>
                                    </v-checkbox>
                                </div>
                                <v-btn x-large class="secondary py-8 text-14 text-transform-none w-100 mt-5" depressed @click="signUp">Sign up</v-btn>
                            </v-card-text>
                        </v-col>
                        <v-col cols="12" lg="5" class="primary px-12 d-none d-md-flex">
                            <div class="p-relative w-100">
                                <div class="mt-16 pt-16 rightblockimagetext">
                                    <h2 class="text-28-60 white--text">On your account <br/>you easily can:</h2>
                                    <div class="">
                                        <v-list class="bg-transparent">
                                            <v-list-item v-for="item in account_can_list" class="iconafterdash py-4 pl-0">
                                                <v-list-item-icon class="align-self-center my-0">
                                                    <img src="@/assets/icons/svg/star-white-rounded.svg" class="" alt="" width="40">
                                                </v-list-item-icon>
                                                <v-list-item-content class="">
                                                    <v-list-item-title class="white-space-initial">
                                                        <div class="d-flex align-center">
                                                            <div class="white--text text-20" v-html="item"></div>
                                                        </div>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-form>
        </v-container>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/LightTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'

export default {
    components: {
        Layout,
        Breadcrumbs
    },
    data: () => ({
        form:{
            email: '',
            password: '',
        },
        snackbar: false,
        valid: true,
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        pass_show: false,
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Signup',
                disabled: true,
            }
        ],
        account_can_list: [
            'Track all your shipments',
            'Keep all the orders in one place',
            'View your order history',
            'Check all the requested quotes',
        ]
    }),
    methods: {
        async signUp(){
            if(this.$refs.form.validate()){
                var pass = false;
                await this.axios.post(process.env.VUE_APP_API + 'customers/v1/register', this.form).then(response => {
                    pass = true;
                }).catch(error => {
                    this.snackbar = true;
                });
                var data = await this.axios.post(process.env.VUE_APP_API + 'customers/v1/login', this.form).catch();

                if(data.status == 200 && pass){

                    this.$store.dispatch('auth/saveToken', {
                        token: data.data.token,
                        remember: this.form.remember
                    });

                    await this.$store.dispatch('auth/fetchUser')

                    var redirect = 'account.dashboard'

                    if(this.$store.getters['auth/toroute']){
                        redirect = this.$store.getters['auth/toroute'];
                        this.$store.dispatch('auth/removeRoute')
                    }
                    this.$router.push({ name: redirect })
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.loginpage{
    .v-text-field{
        fieldset{
            border: none;
        }
    }
    .v-btn.bg-transparent{
        border: 1px solid #CDE0FF;
    }
    .googlelogin.v-btn.bg-transparent:hover{
        border: 1px solid #ededed;
    }
    .dotborder{
        border-bottom: 1px dashed #B9B9B9;
        height: 1px;
    }
    .bg-white-02{
        background-color: rgba(255,255,255,0.2)!important;
    }
    .rightblockimagetext{
        position: relative;
        z-index: 5;
    }
    .checkbox{
        .v-label{
            font-size: 14px;
            line-height: 20px;
            color: $dark;
        }
    }
    .iconafterdash:not(:last-child)::before{
        background-color: transparent;
        border-left: 1px dashed #fff;
        content: " ";
        height: 100%;
        position: absolute;
        opacity: 1;
        right: auto;
        left: 5%;
        top: 65%;
    }
    .iconafterdash img{
        background: #4e91ff;
        z-index: 1;
    }
}
</style>
